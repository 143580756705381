@import '../../../styles/variables';
@import '../../../styles/mixins';
@import './gallery.desktop';
@import './stack.desktop';
@import './map.desktop';

.#{$search-prefix}-main--without-breadcrumb {
  .#{$search-prefix}-results {
    margin-top: $search-spacing-10;
  }
}

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-results {
  display: flex;
  flex-direction: column;
  width: 885px;

  .andes-tooltip__trigger {
    width: 100%;
  }

  @media (width <= 1164px) and (width >= 1024px) {
    width: 744px;
  }

  @media (width <= 1023px) and (width >= 862px) {
    width: 584px;
  }

  @media (width <= 861px) {
    width: 492px;
  }
}

.#{$search-prefix}-layout {
  display: inline-flex;

  &--fade {
    opacity: 0.5;
    transition: opacity 1s ease-in-out;
  }

  .#{$search-prefix}-price {
    &__second-line .price-tag-cents {
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
    }

    &__second-line--decimal .price-tag-cents {
      font-size: inherit;
      font-weight: inherit;
    }

    .price-tag__disabled {
      .price-tag-cents {
        margin-bottom: $search-spacing-4;
        font-weight: $font-weight-semibold;
      }

      &::after {
        top: 50%;
      }
    }
  }

  // sass-lint:disable nesting-depth
  .#{$search-prefix}-installments {
    .#{$search-prefix}-price {
      &__second-line {
        .price-tag-cents {
          font-size: $font-size-10;
        }
      }
    }
  }

  .#{$search-prefix}-item__group--vertical-highlight {
    flex-wrap: wrap;
  }
}

.#{$search-prefix}-layout--grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));
  gap: 16px;
  margin-bottom: 16px;

  @media (width <= 1023px) {
    grid-template-columns: repeat(2, minmax(238px, 1fr));
    gap: 10px;
  }
}

.#{$search-prefix}-layout--stack {
  display: grid;
  grid-template-columns: repeat(1, minmax(240px, 1fr));
  gap: 0;
}

.#{$search-prefix}-item__pub-container {
  .#{$search-prefix}-item__pub-label {
    color: $search-gray-600;
    font-size: $font-size-14;

    &--blue {
      color: $andes-blue-500;
    }
  }
}

.#{$search-prefix}-item {
  &__title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &__title-compats {
    -webkit-line-clamp: 3;
  }
}

.#{$search-prefix}-item__brand-discoverability {
  @include text-crop(4px, 0);

  color: $search-text-color;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (width <= 1164px) {
  .#{$search-prefix}-layout {
    justify-content: center;
  }
}

.#{$search-prefix}-item__highlight-label {
  &.#{$search-prefix}-value-proposition__pill {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.#{$search-prefix}-result {
  .#{$search-prefix}-result__highlight-container {
    padding-bottom: $andes-spacing-4;

    .#{$search-prefix}-item__highlight-label {
      font-size: $font-size-10;
      font-weight: $font-weight-semibold;
      padding: $search-spacing-2 $search-spacing-6;
      border-radius: $search-spacing-2;
      line-height: $line-height-base;
      margin: 0;

      &--TIME_LABEL_HIGHLIGHT {
        font-size: $font-size-12;
      }
    }
  }
}

.ui-search-result__shops-pills--absolute {
  display: none;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 1;

  .ui-pb {
    margin: 0;
  }
}

.ui-search-item__group__discount--below {
  .ui-search-item__group__element {
    margin-bottom: 4px;

    .ui-search-price__second-line {
      flex-flow: wrap column;
      align-items: flex-start;
    }

    .ui-search-price__discount {
      line-height: 1.28;
    }
  }
}
