@import './full-popup.common';

$search-fullpopup-z-index: 5;
$search-fullpopup-animation-duration: 0.2s;

.#{$search-prefix}-fullpopup {
  z-index: $search-fullpopup-z-index;
  display: table;
  border-collapse: collapse;
  border-spacing: em(10);
  transform: translateY(50%);
  animation-duration: $search-fullpopup-animation-duration;
  width: 350px;
  border-radius: $border-radius-6;
  margin: 0;
  top: -138px;

  &-down {
    position: fixed;
    top: 58px;
    right: 76px;
    box-shadow: 0 6px 16px 0 $search-gray-r010;
    .andes-snackbar__message {
      padding: 0;
    }
    .andes-snackbar__children {
      padding: 0;
    }

    &-float {
      position: absolute;
      transform: translateY(50%);
      right: 56px;
    }

    &-sticky {
      position: fixed;
      transform: translateY(-50%);
      right: 56px;
    }

    &--animate-show {
      animation-name: search-fullpopup-bottom-show;
      animation-timing-function: ease-in-out;
    }

    &--animate-hide {
      animation-name: search-fullpopup-bottom-hide;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
  &-up {
    box-shadow: 0 6px 16px 0 $search-gray-r010;
    transform: unset;

    &-sticky {
      position: fixed;
      transform: translateY(-85%);
      right: 375px;
      z-index: 9999;
      top: 150px;
    }

    &-float {
      position: absolute;
      transform: translateY(150px);
      @media (width >= 1900px) {
        right: 603px;
      }
      right: 403px;
      z-index: 5;
      &-closeable {
        top: 93px;
      }
    }

    &--animate-show {
      animation-name: search-fullpopup-top-show;
      animation-timing-function: ease-in-out;
    }
    .andes-snackbar__message {
      padding: 0;
    }
    .andes-snackbar__children {
      padding: 0;
    }

    &--animate-hide {
      animation-name: search-fullpopup-top-hide;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes search-fullpopup-bottom-show {
  from {
    opacity: 0;

    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 50%, 0);
  }
}

@keyframes search-fullpopup-bottom-hide {
  0% {
    transform: translate3d(0, -50%, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes search-fullpopup-top-hide {
  0% {
    transform: translate3d(0, -85%, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes search-fullpopup-top-show {
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }
}
