// sass-lint:disable no-vendor-prefixes, no-color-literals, no-qualifying-elements

@import '../../components/pills/pills.styles.scss';

.ui-search-shopping-cart-intervention {
  background-color: #fff;
  width: 100%;
  padding: $andes-spacing-16 0 $andes-spacing-16 $andes-spacing-16;
  margin: 10px 0;

  @media (min-width: 1024px) {
    padding: 24px;
    display: flex;
    border-radius: 6px;
  }

  &__header {
    padding-right: 16px;

    @media (min-width: 1024px) {
      margin-right: 40px;
      position: relative;
      padding: 0;
    }

    .content-progrees {
      width: 100%;
      appearance: none;
      border-radius: 10px;
      height: 4px;
      margin-bottom: 8px;
      margin-top: 20px;

      @media (min-width: 1024px) {
        display: block;
        height: 6px;
      }

      @media (min-width: 1024px) {
        .andes-progress-indicator-linear--small .fill--highlighted:before {
          height: 11px;
          width: 11px;
          bottom: -2px;
        }
      }
    }

    .subtitle {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }

    .subtitle--mt {
      margin-top: $andes-spacing-8;
    }

    .subtitle__link {
      display: none;
      text-decoration: none;
      color: $andes-blue-500;
      font-size: 14px;
      bottom: 0;
      left: 0;
      font-weight: bold;

      @media (min-width: 1024px) {
        display: unset;
        position: absolute;
      }
    }

    .ui-search-icon--chevron {
      display: inline-block;
      width: 6px;
      margin-left: 6px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &-brand-image {
      width: 48px;
      height: 48px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 6px;
      border: 1px solid #ededed;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px;
      }
    }

    &-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      word-wrap: break-word;
      margin-bottom: 12px;

      &--w-100 {
        width: 100%;
      }

      &--w-80 {
        width: 80%;
      }

      @media (min-width: 1024px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        padding-top: 5px;
        margin-bottom: 0;
      }
    }

    &-main-text {
      color: $andes-green-500;
      font-weight: bold;
    }

    &-promoted-text {
      color: $andes-text-color-secondary;
      font-weight: 500;
      font-size: 12px;
      display: block;
      margin-top: 0;
      transform: translateY(-5px);

      @media (min-width: 1024px) {
        margin-top: 10px;
        transform: translateY(0px);
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        margin-top: 0;
        transform: translateY(-5px);
      }
    }

    .ui-search-icon--full {
      display: inline-block;
      height: 17px;
      width: 55px;
      position: relative;
      top: 4px;
      margin-right: 5px;

      @media (min-width: 1024px) {
        height: 22px;
        width: 65px;
        top: 6px;
      }
    }

    .ui-search-icon--full-super {
      display: inline-block;
      height: 18px;
      position: relative;
      top: 5px;
      margin-right: 5px;

      @media (min-width: 1024px) {
        height: 24px;
        top: 7px;
      }
    }

    &-options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      gap: 6px;

      @media (max-width: 350px) {
        gap: 5px;
      }
    }
  }

  .andes-carousel-free {
    padding: 5px;
    margin-top: 16px;

    .andes-carousel-free__slide:not(:last-child) {
      margin-right: 8px !important;
    }
  }

  .card-item {
    box-shadow: 0 3px 5px 0 rgb(0 0 0 /10%);
    border-radius: 6px;
    cursor: pointer;
    line-height: 99%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    width: 170px;

    &--dynamic-width {
      @media (max-width: 767px) {
        width: 149px !important;
      }
    }

    @media (min-width: 862px) {
      width: 170px;
      min-height: 230px;
    }

    @media (min-width: 1024px) {
      min-height: 200px;
      background-color: #fff;
      box-shadow: 0 0 10px -1 $andes-gray-100;
      width: 131px;

      .ui-search-price__discount {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }

    @media (min-width: 1165px) {
      width: 170px;
    }

    .andes-button--full-width {
      height: 24px;
      font-size: 12px;

      @media (min-width: 1024px) {
        height: 32px;
      }
    }

    .andes-input-stepper__wrapper {
      height: 24px;
      text-align: center !important;
      justify-content: center;

      @media (min-width: 1024px) {
        height: 32px;
      }
    }

    .shops__price-second-line__label {
      margin-top: -3px;
    }

    .ui-search-price__second-line {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__label {
        display: flex;
        white-space: nowrap;
        padding: 0px 0px 8px;
      }
    }

    .ui-search-price__part {
      font-size: $font-size-14 !important;
      margin-bottom: 5px;
      margin-right: 5px;

      @media (min-width: 1024px) {
        font-size: 16px !important;
      }

      @media (min-width: 1440px) {
        font-size: 18px !important;
      }
    }

    .ui-search-price__original-value .andes-money-amount {
      &__fraction,
      &__currency-symbol {
        font-size: $font-size-10 !important;
        display: inline-flex;

        @media (min-width: 1024px) {
          font-size: $font-size-12 !important;
        }
      }
    }

    .shops__price-discount {
      font-size: $font-size-12;
      color: $andes-green-500;
      white-space: nowrap;
    }

    .ui-search-price__discount {
      font-size: $font-size-12;
      color: $andes-green-500;
    }

    &--super {
      @media (min-width: 1024px) {
        height: 295px;
      }
    }

    &-img {
      width: 100%;
      height: 90px;
      margin-top: 8px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      @media (min-width: 1024px) {
        width: 100%;
        height: 146px;
        border-bottom: 1px solid $andes-gray-100;
        margin-top: 0;
      }
    }

    &__reviews-content {
      display: flex;
      padding: 0px 8px 12px;

      @media (min-width: 1024px) {
        padding: 3px 12px;
        font-size: 20px;
      }
    }

    .card-content {
      padding: 8px 0;
      flex-grow: 1;

      &__title {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-size: 10px;
        color: $andes-text-color-secondary;
        font-weight: 400;
        line-height: 1.3;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        padding: 0 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-decoration: none;
        height: 25px;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }

        @media (min-width: 1024px) {
          font-size: 12px;
          color: $andes-text-color-primary;
          -webkit-line-clamp: 2;
          height: 32px;
        }
      }

      &__title:hover {
        text-decoration: none;
      }

      &__item-description {
        padding: 8px 10px 0 8px;
        font-weight: 500;
        @media (min-width: 1024px) {
          padding: 8px 12px 0;
        }
      }

      &__item-currency {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        @media (min-width: 1024px) {
          margin-top: 8px;
          font-size: 20px;
        }
      }

      &__item-discount {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $andes-green-500;
        display: inline-block;
        margin-left: 4px;

        @media (min-width: 1024px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      &__decimal-price {
        font-size: 8px;
        position: relative;
        color: unset;
      }

      &__symbol {
        margin-right: 3px;
      }

      &__super-description {
        padding: 8px 12px;
        font-size: 20px;
        font-weight: 500;

        @media (min-width: 1024px) {
          padding: 8px 12px 0;
          padding-top: 8px;
          font-size: 20px;
        }
      }

      &__super-description .shops__price-second-line__label {
        margin-top: -8px;
      }

      .ui-search-icon--full {
        width: 32px;
        display: block;
        margin: 0 10px;

        @media (min-width: 1024px) {
          margin: 8px 0 4px 12px;
          width: 35px;
        }
      }

      .action-content {
        width: 100%;
        padding: 0 12px;

        .andes-button {
          width: 100%;
          background-color: $andes-blue-150;
          color: $andes-blue-500;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          height: 32px;
        }

        .andes-button:hover {
          background-color: $andes-blue-150;
          color: $andes-blue-500;
        }
      }

      &__pills-content {
        display: flex;
        flex-direction: column;
        grid: 3px;
        padding: 0px 8px 12px;
        font-size: 14px;

        @media (min-width: 1024px) {
          padding: 3px 12px;
          font-size: 20px;
        }

        .content-label {
          display: flex;
          align-items: center;
          font-size: 12px;
        }

        .free-shipping {
          color: $andes-green-500;
          font-weight: bold;
          font-size: 12px;
        }

        .icon-label {
          margin-right: 3px;
        }

        .pads-intervention-label {
          color: $andes-green-500;
          font-weight: 600;
          white-space: nowrap;
          margin-top: 4px;
        }

        .rebates-pill {
          background-color: $andes-blue-200;
          border-radius: rem(2px);
          box-decoration-break: clone;
          padding: rem(1.5px) rem(4px) rem(1.5px) rem(4px);
          -webkit-box-decoration-break: clone;

          // Text-related styles
          color: $andes-blue-500;
          font-size: $font-size-12;
          font-weight: $font-weight-semibold;
          line-height: $andes-spacing-24;
        }

        .rebates-pill > svg {
          flex-shrink: 0;
          margin: 0 rem(6px) 0 rem(4px);
          transform: scale(1.23) translateY(2.5px);
        }

        &:has(.rebates-pill) {
          padding-block: 0 7px;
        }

        .icon-pill {
          display: inline-block;
          margin-top: 3px;
        }

        .icon-pill svg {
          margin: 0;
        }
      }
    }
    .ui-search-card-add-to-cart {
      width: 90%;
      margin: auto;
      margin-bottom: 10px;

      @media (min-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    .ui-search-item__group--add-to-cart {
      z-index: 1000;
    }

    .andes-button--medium {
      padding: 0 10px;
    }
  }

  .content-options {
    margin-right: 16px;
    margin-top: 23px;

    &--tablet {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    .andes-button {
      width: 100%;
      padding: 15px;
      background-color: $andes-blue-150;
      color: $andes-blue-500;
      margin-top: 0;
      height: 47px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;

      &:hover {
        background-color: $andes-blue-150;
        color: $andes-blue-500;
      }
    }
  }

  .andes-carousel-snapped {
    padding: 6px 0 0 3px;

    &--super {
      height: 305px;
    }

    &__container {
      width: 100%;

      @media (min-width: 1024px) {
        width: 62%;
      }
      @media (min-width: 1165px) {
        width: 65%;
      }
    }

    &__controls-wrapper {
      height: 100%;
    }

    &__slide {
      padding: 1% 0px;
      width: calc((100% - 24px) / 3) !important;

      &:nth-child(3),
      &:nth-child(6) {
        margin-right: 15px !important;
      }
    }

    &__control {
      height: 32px;
      width: 32px;
      top: 53% !important;
    }

    &__control--previous {
      span {
        height: 16px !important;
        width: 16px !important;
      }
    }

    &__control--next {
      span {
        height: 16px !important;
        width: 16px !important;
      }
    }

    &--scroll-hidden {
      margin: unset;
    }
  }

  @media (min-width: 1024px) {
    margin: 8px 0;
    padding: 24px;
    display: flex;
  }

  .end-card {
    box-shadow: 0 3px 5px 0 $andes-gray-100;
    border-radius: 6px;
    display: flex;

    @media (min-width: 1024px) {
      background-color: $andes-white;
    }

    &-content {
      min-height: 200px;
      padding: 0 $andes-spacing-8;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      @media (min-width: 768px) {
        gap: 25px;
      }

      @media (min-width: 862px) {
        width: 161px;
        min-height: 230px;
      }

      @media (min-width: 1024px) {
        width: 131px;
        max-width: 99%;
        min-height: 200px;
        padding: 0;
      }

      @media (min-width: 1165px) {
        width: 170px;
      }
    }

    &-image {
      width: 120px;
      height: 90px;
      object-fit: cover;
      object-position: center;
      align-self: center;

      @media (min-width: 1024px) {
        width: 131px;
        height: 146px;
      }

      @media (min-width: 1165px) {
        width: 159px;
      }
    }

    &-text {
      font-size: $font-size-12;
      text-align: center;
      color: $andes-text-color-primary;
      font-weight: $font-weight-regular;
      width: 90%;
      margin: $andes-spacing-12 auto;
      flex-grow: 1;

      @media (min-width: 1024px) {
        margin-top: $andes-spacing-8;
        padding: 0 $andes-spacing-12;
        font-size: $font-size-16;
        margin-bottom: $andes-spacing-16;
      }
    }

    &-button {
      margin: auto;
      width: 100%;
      text-align: center;
      height: 24px;

      @media (min-width: 1024px) {
        height: 32px;
        margin-bottom: 28px;
        width: 142px;
        text-align: center;
      }
    }
  }

  .button-with-label {
    .ui-search-card-add-to-cart {
      width: 90%;
      margin: auto;
      min-height: 50px;

      @media (min-width: 1024px) {
        min-height: 60px;
      }
    }

    .end-card {
      &-button {
        width: 100%;
        margin-bottom: 25px;
        text-align: center;
        height: 24px;

        @media (min-width: 1024px) {
          height: 32px;
          margin-bottom: 28px;
          width: 142px;
          text-align: center;
        }
      }
    }
  }

  .button-without-label {
    .ui-search-card-add-to-cart {
      width: 90%;
      margin: auto;
      min-height: 30px;

      @media (min-width: 1024px) {
        min-height: 40px;
      }
    }

    .end-card {
      &-button {
        margin: auto;
        width: 100%;
        margin-bottom: 7px;
        text-align: center;
        height: 24px;

        @media (min-width: 1024px) {
          height: 32px;
          margin-bottom: 8px;
          width: 117.9px;
          text-align: center;
        }

        @media (min-width: 1165px) {
          width: 90%;
        }
      }
    }
  }
}

.repurchase-intervention-desktop {
  margin: 0;
  padding: $search-spacing-24 $search-spacing-24 $search-spacing-24 $search-spacing-22;

  .andes-carousel-snapped__container {
    width: 100%;

    .andes-carousel-snapped__header {
      margin-bottom: $search-spacing-16;
      .andes-carousel-snapped__title {
        font-size: $search-spacing-24;
        font-weight: 600;
        line-height: $search-spacing-30;
      }
    }

    .andes-carousel-snapped {
      @media (min-width: 1165px) {
        min-width: 844px;
      }

      padding: 0 0 0 $search-spacing-2;
    }

    .andes-carousel-snapped__slide {
      width: auto !important;
      margin-right: $search-spacing-12 !important;
      padding-bottom: 0;

      @media (min-width: 1024px) {
        height: 341px;
      }
  
      @media (min-width: 1165px) {
        width: 158px !important;
      }
    }

    .end-card {
      width: 131px;

      @media (min-width: 1165px) {
        width: 158px;
      }
  
      .end-card-text {
        font-size: $font-size-14;
      }
    }
  }
}

.grid__intervention {
  @media (min-width: 1024px) {
    border-radius: 6px;
  }
}
